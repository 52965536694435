import { Login } from "./Login";
import { useEffect, useState } from "react";
import { api } from "../utils/api";

import 'bootstrap/dist/css/bootstrap.min.css';

export const Admin = () => {
  const [links, setLinks] = useState([]);

  const authed =
    localStorage.getItem("login") && localStorage.getItem("password");

  useEffect(() => {
    if (authed) {
      api
        .get("/invoices")
        .then((res) => {
          setLinks(res.data);
        })
        .catch((err) => {
          alert("Error fetching links");
        });
    }
  }, [authed]);

  if (!authed) {
    return <Login />;
  }

  return (
    <div className="App">
      <div className="wrapper">
        <h1>Invoices</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            const amount = parseFloat(e.target.amount.value);
            const name = e.target.name.value;
            const purpose = e.target.purpose.value;

            api
              .post("/create-invoice", { amount, name, purpose })
              .then((res) => {
                setLinks([...links, res.data]);
              })
              .catch((err) => {
                alert("Error creating invoice");
              });
          }}
        >
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">Amount</span>
            <input type="text" class="form-control" placeholder="Amount" name="amount" aria-label="Username" aria-describedby="basic-addon1" />
            <span class="input-group-text" id="basic-addon1">Name</span>
            <input type="text" class="form-control" placeholder="Name" name="name" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
          <textarea class="form-control" name="purpose" placeholder="Leave a payment purpose here"></textarea>
          <button type="submit" class="btn btn-primary">Create Invoice</button>
        </form>

        <table className="table table-striped table-hover">
          <thead className="table-primary">
            <tr>
              <th>Invoice Number</th>
              <th>Url</th>
              <th>Amount</th>
              <th>Name</th>
              <th>Purpose</th>
            </tr>
          </thead>
          <tbody>
            {links?.map((link, index) => (
              <tr key={link.id}>
                <td>{index + 1}</td>
                <td>
                  <a href={link.url}>{link.url}</a>
                </td>
                <td>{link.amount}</td>
                <td>{link.name}</td>
                <td>{link.purpose}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
